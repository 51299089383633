export default {
    "home": {
        "slider": true,
        "sliders": [
            {
                "title": {
                    "br": "Data Center no Brasil",
                    "en": "Data Center in Brazil",
                    "es": "Centro de datos en Brasil"
                },
                "subTitle": {
                    "br": "Com a melhor infraestrutura e rede de segurança, tenha o melhor que há em qualidade conosco.",
                    "en": "With the best infrastructure and safety net, have the best quality with us.",
                    "es": "Con la mejor infraestructura y red de seguridad, tenga la mejor calidad con nosotros."
                },
                "action": {
                    "color": "info",
                    "type": "internal",
                    "href": "/infrastructure",
                    "icon": ["fas", "info-circle"],
                    "text": {
                        "br": "Quero saber mais sobre a estrutura",
                        "en": "I want to know more about the structure",
                        "es": "Quiero saber más sobre la estructura"
                    }
                }
            },
            {
                "title": {
                    "br": "Bare Metal",
                    "en": "Bare Metal",
                    "es": "Bare Metal"
                },
                "subTitle": {
                    "br": "Tenha seu próprio dedicado utilizando todos os recursos, sem compartilhamento!",
                    "en": "Have your own dedicated using with every resource without sharing!",
                    "es": "¡Tenga su propio uso dedicado con cada recurso sin compartir!"
                },
                "action": {
                    "color": "info",
                    "type": "internal",
                    "href": "/baremetal",
                    "icon": ["fas", "info-circle"],
                    "text": {
                        "br": "Ver baremetal",
                        "en": "Ver baremetal",
                        "es": "Ver baremetal"
                    }
                }
            },
            {
                "title": "Colocation",
                "subTitle": {
                    "br": "Infraestrutura com equipamentos de ponta, para garantir a confiabilidade de toda a operação.",
                    "en": "Infrastructure with state-of-the-art equipment to ensure the reliability of the entire operation.",
                    "es": "Infraestructura con equipos de última generación para garantizar la confiabilidad de toda la operación."
                },
                "action": {
                    "color": "info",
                    "type": "internal",
                    "href": "/colocation",
                    "icon": ["fas", "info-circle"],
                    "text": {
                        "br": "Saber mais",
                        "en": "Saber mais",
                        "es": "Saber mais"
                    }
                }
            }
        ],
        "title": {
            "br": "Data Center no Brasil",
            "en": "Data Center in Brazil",
            "es": "Centro de datos en Brasil"
        },
        "subTitle": {
            "br": "Com a melhor infraestrutura e rede de segurança, tenha o melhor que há em qualidade conosco.",
            "en": "With the best infrastructure and safety net, have the best quality with us.",
            "es": "Con la mejor infraestructura y red de seguridad, tenga la mejor calidad con nosotros."
        },
        "action": {
            "color": "info",
            "type": "internal",
            "href": "/infrastructure",
            "icon": ["fas", "info-circle"],
            "text": {
                "br": "Quero saber mais sobre a estrutura",
                "en": "I want to know more about the structure",
                "es": "Quiero saber más sobre la estructura"
            }
        }
    },
    "vps": {
        "title": "VPS",
        "subTitle": {
            "br": "Servidores de alta disponibilidade em hardware dedicado e alta capacidade de escalonamento.",
            "en": "High availability servers on dedicated hardware and high scalability.",
            "es": "Servidores de alta disponibilidad en hardware dedicado y alta escalabilidad."
        },
        "action": false
    },
    "baremetal": {
        "title": "Bare Metal",
        "subTitle": {
            "br": "Tenha seu próprio dedicado utilizando todos os recursos, sem compartilhamento!",
            "en": "Have your own dedicated using with every resource without sharing!",
            "es": "¡Tenga su propio uso dedicado con cada recurso sin compartir!"
        },
        "action": false
    },
    "colocation": {
        "title": "Colocation",
        "subTitle": "",
        "action": false
    },
    "anti_ddos": {
        "title": "Anti-DDoS",
        "subTitle": {
            "br": "Sem alterações de rota, sem aumento de ping ou atraso para mitigar seu ataque.",
            "en": "Sem alterações de rota, sem aumento de ping ou atraso para mitigar seu ataque.",
            "es": "Sem alterações de rota, sem aumento de ping ou atraso para mitigar seu ataque."
        },
        "action": false
    },
    "infrastructure": {
        "title": {
            "br": "Infraestrutura",
            "en": "Infrastructure",
            "es": "Infraestructura"
        },
        "subTitle": {
            "br": "Infraestrutura com equipamentos de ponta, para garantir a confiabilidade de toda a operação.",
            "en": "Infrastructure with state-of-the-art equipment to ensure the reliability of the entire operation.",
            "es": "Infraestructura con equipos de última generación para garantizar la confiabilidad de toda la operación."
        },
        "action": false
    },
    "legal": {
        "title": {
            "br": "Políticas Legais",
            "en": "Legal Policies",
            "es": "Políticas legales"
        },
        "subTitle": {
            "br": "Aqui você encontrá mais sobre as politicas da BB Host.",
            "en": "Here you will find more about BB Host policies.",
            "es": "Aquí encontrará más información sobre las políticas de BB Host."
        },
        "action": false
    },
    "about_us": {
        "title": {
            "br": "Sobre Nós",
            "en": "About Us",
            "es": "Sobre nosotros"
        },
        "subTitle": {
            "br": "Aqui você encontra mais sobre a história da BB Host.",
            "en": "Here you can find more about the history of BB Host.",
            "es": "Aquí puede encontrar más información sobre la historia de BB Host."
        }
    },
    "blog": {
        "title": "Blog",
        "subTitle": "",
        "action": false
    },
    "404": {
        "title": {
            "br": "Pagina não encontrada",
            "en": "Page not found",
            "es": "Página no encontrada"
        },
        "subTitle": ""
    },
    "500": {
        "title": "ERROR 500",
        "subTitle": {
            "br": "Ocorreu um erro do lado do servidor.",
            "en": "A server-side error has occurred..",
            "es": "Ha ocurrido un error del lado del servidor.."
        }
    },
    "status": {
        "slider": false
        }
};