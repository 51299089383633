export const baremetal_faqs = {
    "highlights": [
        {
            "name": "datacenter",
            "icon": "Business",
            "icon_color": "info",
            "text": {
                "br": "Datacenter no Brasil",
                "en": "Datacenter no Brasil",
                "es": "Datacenter no Brasil"
            },
            "subText": {
                "br": "Estrutura localizada em Cotia- SP, contando com diversas modernidades e certificações, a fim de entregar uma ótima experiência a nossos clientes.",
                "en": "Structure located in Cotia-SP, with several modern features and certifications in order to deliver a great experience to our customers.",
                "es": "Estrutura localizada em Cotia- SP, contando com diversas modernidades e certificações a fim de entregar uma ótima experiência a nossos clientes."
            },
            "more_link": {
                "name": "datacenter_link",
                "text": {
                    "br": "Saber mais.",
                    "en": "Saber mais.",
                    "es": "Saber mais."
                },
                "color": "primary",
                "icon": ["fas", "info-circle"],
                "link": "/infrastructure",
                "type": "internal"
            }
        },
        {
            "name": "anti_ddos",
            "icon": "Security",
            "icon_color": "success",
            "text": {
                "br": "Mitigação eficiente",
                "en": "Mitigação eficiente",
                "es": "Mitigação eficiente"
            },
            "subText": {
                "br": "Sem alterações de rota, sem aumento de ping ou atraso para mitigar seu ataque, com 296 Tbps de capacidade.",
                "en": "No route changes, no ping boost or delay to mitigate your attack, with 296 Tbps of capacity.",
                "es": "Sem alterações de rota, sem aumento de ping ou atraso para mitigar seu ataque, com 296 Tbps de capacidade."
            },
            "more_link": {
                "name": "anti_ddos_link",
                "text": {
                    "br": "Saber mais.",
                    "en": "Saber mais.",
                    "es": "Saber mais."
                },
                "color": "primary",
                "icon": ["fas", "info-circle"],
                "link": "/infrastructure?index=anti_ddos",
                "type": "internal"
            }
        },
        {
            "name": "conectividade",
            "icon": "Public",
            "icon_color": "info",
            "text": {
                "br": "Conectividade",
                "en": "Conectividade",
                "es": "Conectividade"
            },
            "subText": {
                "br": "Tenha o melhor ping e estabilidade do Brasil, garantindo toda a segurança e estabilidade, além de uma rede protegida.",
                "en": "Have the best ping and stability in Brazil, ensuring all security and stability, in addition to a protected network.",
                "es": "Tenha o melhor ping e estabilidade do Brasil, garantindo toda a segurança e estabilidade, além de uma rede protegida."
            },
            "more_link": {
                "name": "network_link",
                "text": {
                    "br": "Saber mais.",
                    "en": "Saber mais.",
                    "es": "Saber mais."
                },
                "color": "primary",
                "icon": ["fas", "info-circle"],
                "link": "/infrastructure?index=network",
                "type": "internal"
            }
        }
    ],
    "list": [
        {
            "title": "Qual o prazo de ativação ?",
            "text": "O tempo de ativação pode variar com o tipo de máquina contratada."
        },
        {
            "title": "Como funciona a mitigação ?",
            "text": "A mitigação trabalha a todo momento identificando qualquer anomalia em seu tráfego e mitigando se necessário, possuindo mais de 296 Tbps no plano incluso. O DDoS Pro funciona em uma camada extra, analisando tudo que chega a seu servidor e permitindo apenas as conexões compatíveis com o jogo ou aplicação."
        },
        {
            "title": "Como é cobrado o tráfego ?",
            "text": "Cobramos apenas o uso em upload, sendo contabilizado seu uso. É possível acompanhar pelo painel qual seu uso atual e realizar o aumento de plano."
        },
        {
            "title": "É permitido jogos nos servidores BB Host ?",
            "text": "Sim, autorizamos e possuímos uma das principais mitigações no mercado brasileiro especializada em jogos."
        },
        {
            "title": "O que são servidores spot ?",
            "text": "São servidores únicos, chances únicas de adquirir servidores de alta qualidade a um baixo preço. Estoques limitados."
        }
    ]
};